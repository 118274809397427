<template>
  <div class="container justify-content-center">

    <h3>user</h3>
    <div v-if="user">{{this.user}}</div>

    <h3>data</h3>
    <div v-if="data">{{data}}</div>

    <h3>config</h3>
    <div v-for="(profil,idb) in config.profils" :key="idb" :id="profil.id" class="mb-2">
      {{profil}}
      <br/>
    </div>
    <hr/>
    <div v-if="config">{{config}}</div>


    <NavBar :user="user" @setView="setView"/>
    <BottomBar/>

  </div>
</template>

<script>
import MyFlashDS from "../services/MyFlashDS";
import NavBar from '@/components/NavBar.vue'
import BottomBar from '@/components/BottomBar.vue'

export default {
  data(){
      return {
        user: null,
        config: null,
        data: null,
      }
  },
  name: "UtfView",
  //props: ['user'],
  components: {
    NavBar, BottomBar,
  },
  methods: {
  },
  mounted() {
    console.log('MOUNT UserData');
    console.log('route', this.$route.query, this.$route.params);
    MyFlashDS.identifyUser().catch(()=>{
        this.$router.push('/');
      }).then(res=>{
        console.log('<MyFlashDS.identifyUser>', res.data);
        this.user = res.data;
        MyFlashDS.dataGetUser(this.user.id, 'data email pseudo').then(res=>{
          this.data = res.data.data;
        });
      });
    MyFlashDS.getConfig('').then(res => {
        this.config = res.data;
    });

  }
}
</script>


<style scoped>

</style>

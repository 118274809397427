<template>
  <div class="container justify-content-center" style="padding-left: 0">
  <div v-if="t_rows">

        <h3>{{$t('tags')}}</h3>
        <vue-good-table styleClass="vgt-table condensed striped" max-height="500px" :columns="t_columns" :rows="t_rows">
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'ownerX'">{{props.row.owner}} <a v-if="!props.row.owner" href="#" @click="doUpdOwner(props.row._id)">à moi</a> <a :href="'/flash/'+props.row.no">lien</a></span>
              <span v-if="props.column.field == 'id'"><a target="tags" :href="'/flash/'+props.row.no+'/'+props.row.id">{{props.row.no}}</a> {{props.row.id}}</span>
              <span v-if="props.column.field == 'owner'">{{props.row.owner ? props.row.owner : '-'}}</span>
              <span v-if="props.column.field == 'customer'">{{props.row.customer ? props.row.customer : '-'}}</span>
            </template>
        </vue-good-table>
        Affecter des flash à un client:<br/>
        <b-form-textarea v-model="affectation.urls" class="mb-2" placeholder=".../flash/50000/00112233445566x0001"></b-form-textarea>
        cust: <select v-model="affectation.customer"><option/><option v-for="(x,i) in c_rows" :key="i" :value="x._id">{{x.name}}</option></select>
        <br/><button type="button" @click="doSave" class="btn btn-outline-primary buttons">{{$t('save')}}</button>

  </div>
  </div>
</template>

<script>
import MyFlashDS from "../services/MyFlashDS";
//import ImageUpdater from '@/components/ImageUpdater.vue'

export default {
  data(){
      return {
        user: null,
        t_columns: [
            //{label: this.$i18n.t('id'), field: '_id',},
            {label: this.$i18n.t('id'), field: 'id',},
            {label: this.$i18n.t('customer'), field: 'customer',},
            {label: this.$i18n.t('owner'), field: 'owner',},
            ],
        t_rows: [],

        c_rows: [], // customers
        affectation:{},
      }
  },
  name: "AdminTags",
  //props: ['user'],
  components: {
    //ImageUpdater,
  },
  methods: {
    doSave() {
      console.log('save', this.affectation);
      MyFlashDS.updateTags(this.affectation).then(()=>{
        this.doLoadTags();
      })
    },
    doLoadTags() {
        MyFlashDS.getTags('?all=yes').then(res => {
          this.t_rows=res.data;
        })
    },
    doUpdOwner(x) {
        MyFlashDS.ownerTag({id:x}).then(res => {
            console.log('affect', res);
            this.doLoadTags();
        });
    },
  },
  mounted() {

    MyFlashDS.getCustomers('short').then(res => {
        this.c_rows=res.data;
        this.doLoadTags();
    });
  },
}
</script>

<style scoped>

button {
  width: 20vh;
}

h4 {
  margin-top: 3vh;
}

img {
    max-width: 100px;
    max-height: 100px;
}

</style>

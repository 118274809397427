/*

Vue : {{ $route.params.type }}
*/

<template>
  <div class="container justify-content-center">
    <div>

    <b-modal ref="imgModal" id="imgModal" @ok="doChangeImage">
        <ImageUpdater @imageChange="imageChange"/>
    </b-modal>

    <NavBar :user="user" @setView="setView"/>
    <Bandeau v-if="data" :data="data" :view="$route.params.type" :enableEdit='true'/>

    <UserData v-if="user" :user="user" :view="$route.params.type"/>

    <BottomBar/>

    </div>
  </div>
</template>

<script>
import MyFlashDS from "../services/MyFlashDS";
import NavBar from '@/components/NavBar.vue'
import BottomBar from '@/components/BottomBar.vue'
import UserData from '@/components/UserData.vue'
import Bandeau from '@/components/Bandeau.vue'

export default {
  data(){
      return {
        user: null,
        data:null,
        view: null,
      }
  },
  name: "UserView",
  //props: ['user'],
  components: {
    NavBar, BottomBar,
    UserData,
    Bandeau,
  },
  methods: {
    setView(view) {
      //console.log('UserView,setView', view);
      this.view=view;
    },
    doChangeImage(){}, // TODO a faire ?
    imageChange(){}, // TODO
  },
  mounted() {
    console.log('MOUNT UserData');
    console.log('route', this.$route.query, this.$route.params);
    MyFlashDS.identifyUser().catch(()=>{
        this.$router.push('/');
      }).then(res=>{
        console.log('<MyFlashDS.identifyUser>', res.data);
        this.user=res.data;
        this.data={customer: null, owner:this.user.id}
      });
  }
}
</script>


<style scoped>
/* site original */

div.dataView {
  color:  #d35400 ;
}

.icon-label{
  float: right;
  padding-right: 1vh;
}

.card-header {
  border-top: 5px solid #00B5EC;
  background: rgba(255, 255, 255, 1);
}



/*-------------------------------------------------------*/


div.block {
  color : red;
}

img.img_access {
  width: 15px; height: 15px; margin-bottom: 0.8vh;
}


.icon-label input {
  width: 20px;
}

button {
    cursor: pointer;
    width: 30vh;
    margin-top: 2vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-size: 1rem;
    font-family: ITCAvantGarde, serif;
}

img.logo {
    object-fit: cover;
    height: 40vh;
}

input {
    border: 1px solid var(--couleur-principale);
    width: 45vh;
}












  .buttonsX {
    color: black;
    margin-top: 2vh;
    background-color: rgb(0, 133, 197);
    border-radius: 7px;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-size: 1rem;
    cursor: pointer;
    width: 30vh;
    }

/*
  container: {
    height: "90vh";
    display: "flex";
    alignItems: "center";
    textAlign: "center";
    marginBottom: '10vh';
  }
  button: {
    color: "white";
    marginTop: "2vh";
    backgroundColor: "#0085c5";
    borderRadius: "7px";
    paddingTop: "1vh";
    paddingBottom: "1vh";
    fontSize: "1rem";
    cursor: "pointer";
    width: "30vh";
    "@media only screen and (maxWidth: 1024px)": {
      width: "80vw"
    };
    "@media only screen and (minWidth: 1025px)": {
      maxHeight: "80vh";
      width: "100%"
    }
  };
  kezako: {
    color: "#0085c5";
    backgroundColor: "#FFF";
    marginTop: "2vh";
    border: "2px solid #0085c5";
    borderRadius: "7px";
    fontSize: "1rem";
    paddingTop: "1vh";
    paddingBottom: "1vh";
    cursor: "pointer";
    "@media only screen and (maxWidth: 1024px)": {
      width: "80vw"
    };
    "@media only screen and (minWidth: 1025px)": {
      maxHeight: "80vh";
      width: "100%"
    }
  };
  lien: {
    fontSize: "0.8rem";
    textDecoration: "none";
    color: "grey"
  };
  responsive: {
    height: "40vh"
  }
*/

</style>

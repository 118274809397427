<template>
  <div class="row justify-content-center">
      <b-modal id="whatisit" ref="whatisit" size="xl" scrollable title="Qu'est-ce que c'est" ok-only>
        <div v-html="whatisit"/>
      </b-modal>

      <div class='col'><button type="button" @click="$router.push('/info/whatisit')" class="text-uppercase btn btn-outline-secondary">{{$t('whatisit')}}</button></div>
  </div>
</template>

<script>
import MyFlashDS from "../services/MyFlashDS";

export default {
  name: "WhatIsIt",
  components: {},
  props: [],
  data() {
    return {
      whatisit: '',
    };
  },
  methods: {
  },
  mounted() {
      MyFlashDS.getConfig('whatisit').then(res => {
        this.whatisit=res.data.whatisit;
      });
  }
};
</script>

<style scoped>
/* récup MainView */
button {
    cursor: pointer;
    width: 30vh;
    margin-top: 2vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-size: 1rem;
    font-family: ITCAvantGarde, serif;
}
</style>

<template>
  <div class="container justify-content-center">

    <NavBar :user="user" />

<div class="container text-center">
  <div class="row">
    <div class="col-2 p-0">

      <img class="" src="/logo_circle.png" alt="">
      <h1 class="text-uppercase">Administration</h1>

      <div @click="displ('user')" :class="this.display=='user' ? 'selected':''">Utilisateurs</div>
      <div @click="displ('cust')" :class="this.display=='cust' ? 'selected':''">Partenaires</div>
      <div @click="displ('tags')" :class="this.display=='tags' ? 'selected':''">Tags</div>
      <div @click="displ('prof')" :class="this.display=='prof' ? 'selected':''">Gestion profils</div>
      <div @click="displ('cgxs')" :class="this.display=='cgxs' ? 'selected':''">CGU / CGV / Mentions</div>
      <!--<div @click="displ('regl')" :class="this.display=='regl' ? 'selected':''">Réglages</div>-->

    </div>
    <div class="col p-0">

     <AdminUsers v-if="display=='user'"/>

     <AdminCustomers v-if="display=='cust'"/>

      <AdminProfils v-if="display=='prof'"/>

      <AdminConfig v-if="display=='cgxs'" @onAlert="onAlert"/>

      <div v-if="display=='regl'">
      </div>

      <AdminTags v-if="display=='tags'"/>

    </div>
  </div>
</div>

    <div>
    </div>
  </div>
</template>

<script>
import MyFlashDS from "../services/MyFlashDS";
import NavBar from '@/components/NavBar.vue'
import AdminConfig from '@/components/AdminConfig.vue'
import AdminProfils from '@/components/AdminProfils.vue'
import AdminUsers from '@/components/AdminUsers.vue'
import AdminCustomers from '@/components/AdminCustomers.vue'
import AdminTags from '@/components/AdminTags.vue'

export default {
  data(){
      return {
        user: null,
        display: 'user',
        data:{},
      }
  },
  name: "AdminView",
  //props: ['user'],
  components: {
    NavBar,
    AdminConfig, AdminUsers, AdminCustomers, AdminTags, AdminProfils,
  },
  methods: {
    onAlert(variant, message) {this.$emit('onAlert', variant, message)},
    displ(x) {this.display=x},
  },
  mounted() {
    console.log('MOUNT AdminView');
    MyFlashDS.identifyUser().then(res=>{
            this.user=res.data;
            this.onAlert('info', 'Panneau administration prêt');
            });
  },
}
</script>

<style scoped>

/* de CircleComp */
h1 {
  color: var(--couleur-principale);
  font-size: 1vmax;
}

div.red {background-color: red;}

h3 {
  margin-top: 5vh;
}

div.selected {
  background-color: #87bfe9;
}

img {
    max-width: 100%;
}

</style>

<template>
<div>

    <nav class="navbar fixed-bottom navbar-light bg-white">
      <router-link :class="$route.path=='/info/whatisit' ? 'select' : ''" to="/info/whatisit">&nbsp;?</router-link>
      -
      <router-link :class="$route.path=='/info/mentions' ? 'select' : ''" to="/info/mentions">Mentions Légales</router-link>
      -
      <router-link :class="$route.path=='/info/cgu' ? 'select' : ''" to="/info/cgu">CGU</router-link>
      -
      <router-link :class="$route.path=='/info/cgv' ? 'select' : ''" to="/info/cgv">Confidentialité</router-link>
    </nav>

    <br/><br/>
</div>
</template>

<script>

export default {
  name: "BottomBar",
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  }
};
</script>

<style scoped>

nav {
  margin-left: 5vh;
  margin-right: 5vh;
}

div.all {
  width: 100vh;
  height: 100vw;
}

a.select {
  color : var(--couleur-principale) !important;
}

a {
  color : gray !important;
  text-decoration: none !important;
}
</style>

/*

prop : text, img
*/

<template>
  <div class="container text-center">
    <div class="row justify-content-center">
        <div :id="img" class="circle text-center">
            <img :class="img" :src="'/logo_circle'+(img?img:'')+'.png'" alt="">
        </div>
    </div>
    <div class="row justify-content-center">
        <h1 class="text-uppercase font-weight-bold">{{text}}</h1>
    </div>
  </div>
</template>

<script>
/* Code generated with AutoHTML Plugin for Figma */

export default {
  name: "CircleComp",
  components: {},
  props: ['text','img'],
  data() {
    return {};
  },
};
</script>

<style scoped>

h1 {
  color: var(--couleur-principale);
  font-size: 2vh;
  font-weight: bold;
}

div#_text {
  height: 50vh;
}

img._text {
    max-width: 30vh;
    width: 30vh;
}

img {
  /* max-width: inherit; */
  max-width: 250px;
  width: 25vw;
}

div.circle {
  //height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>


<template>
  <div class="container justify-content-center">

            <h5 class="mb-4">Mes contacts:</h5>
            <table class="table">
            <thead>
                <tr>
                  <th scope="col">{{this.$i18n.t('pseudo')}}</th>
                  <th scope="col">accès mes private</th>
                  <th scope="col">demande envoyée</th>
                  <th scope="col">accès ses private</th>
                  <th scope="col">demande externe</th>
                </tr>
              </thead>
              <tbody>
                  <tr v-for="(user, index) in c_rows" :key="index">
                    <th scope="row">{{user.pseudo}}</th>
                    <td><input type="checkbox" disabled :checked="user.gp" /></td>
                    <td><input v-if="!user.egp" type="checkbox" disabled :checked="user.ap" /></td>
                    <td>
                        <button v-if="!user.egp & !user.ap" type="button" @click="doApAdd(user.id)" class="btn btn-outline-primary buttons">{{$t('demander')}}</button>
                        <input v-else type="checkbox" disabled :checked="user.egp" />
                    </td>
                    <td>
                        <button v-if="user.eap & !user.gp" type="button" @click="doEapAccept(user.id)" class="btn btn-outline-primary buttons">{{$t('accept')}}</button>
                    </td>
                  </tr>
                </tbody>
            </table>
<!--
<div id="Contacts" class="container mb-5" style="border: medium;">
   <div class="row pt-2 justify-content-center">
      <div class="card card-outline-secondary" style="border: 2px solid rgb(238, 238, 238); box-shadow: rgb(204, 204, 204) 0px 2px 3px; padding: 7vh;">
         <div class="mb-5">
            <h5 class="mb-4">Mes contacts:</h5>
         </div>
         <div class="mb-5">

            <vue-good-table v-if="privates" styleClass="vgt-table condensed striped" max-height="500px" :columns="c_columns" :rows="c_rows">
                <template slot="table-row" slot-scope="props">
                  <div v-if="props.column.field == 'pseudo'">{{props.row.pseudo}}</div>
                  <div v-if="props.column.field == 'id'">{{props.row.id}}</div>
                  <div v-if="props.column.field == 'gp'"><input type="checkbox" disabled :checked="props.row.gp" /></div>
                  <div v-if="props.column.field == 'ap'"><input v-if="!props.row.egp" type="checkbox" disabled :checked="props.row.ap" /></div>
                  <div v-if="props.column.field == 'egp'">
                        <button v-if="!props.row.egp & !props.row.ap" type="button" @click="doApAdd(props.row.id)" class="btn btn-outline-primary buttons">{{$t('demander')}}</button>
                        <input v-else type="checkbox" disabled :checked="props.row.egp" /> </div>
                  <div v-if="props.column.field == 'eap'"><button v-if="props.row.eap & !props.row.gp" type="button" @click="doEapAccept(props.row.id)" class="btn btn-outline-primary buttons">{{$t('accept')}}</button></div>
                </template>
            </vue-good-table>

         </div>
      </div>
   </div>
</div>
-->
</div>
</template>

<script>
import MyFlashDS from "../services/MyFlashDS";

export default {
  data(){
      return {
        user: null,
        data: null,
        c_columns: [
            {label: this.$i18n.t('pseudo'), field: 'pseudo',},
            //{label: this.$i18n.t('id'), field: 'id',},

            {label: this.$i18n.t('a accès à mes private'), field: 'gp',},
            {label: this.$i18n.t('demande envoyée'), field: 'ap',},
            {label: this.$i18n.t('accès à ses private'), field: 'egp',},
            {label: this.$i18n.t('demandes externes'), field: 'eap',},
            ],
        c_rows: null,
        privates: null,
      }
  },
  name: "UserContacts",
  //props: ['user'],
  components: {
  },
  methods: {
    doLoadContact() {
      MyFlashDS.dataGetUser(this.user.id, 'privates').then(res=>{
        console.log(res.data)
        this.privates=res.data;
        let ulist=[];
        ['gp','ap','eap','egp'].forEach(k=>{
          this.privates[k].forEach(x=>{
            let elmt=ulist.find(y=>y.id==x.id)
            if (!elmt) {
                elmt={pseudo:x.pseudo, id:x.id, gp:false, ap:false, egp:false, eap:false}
                ulist.push(elmt)
                }
            elmt[k]=true;
            //ulist.push({pseudo:x.pseudo, gp:(k=='gp'), ap:(k=='ap'), eap:(k=='eap'), egp:(k=='egp')})
          })
        });
        this.c_rows=ulist;
      });
    },
    doEapAccept(uid){ // addPrivate -> groupPrivate
          MyFlashDS.saveUser({id:this.user.id, groupPrivate:'+'+uid}).then(res=>{
            this.$emit('onAlert', "info", "Ajouté, "+res.data);
            this.doLoadContact();
          });
    },
    doApAdd(uid) {
          MyFlashDS.saveUser({id:this.user.id, addPrivate:'+'+uid}).then(res=>{
            this.$emit('onAlert', "info", "Demande envoyée "+res.data);
            this.doLoadContact();
          });
    },
  },
  mounted() {

    MyFlashDS.identifyUser().then(res=>{
        this.user=res.data;
        this.doLoadContact();
        });
  }
}
</script>


<style scoped>
</style>

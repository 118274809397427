<template>
  <div class="container justify-content-center" style="padding-left: 0">
  <div v-if="c_rows">

    <b-modal ref="imgModal" id="imgModal" @ok="doChangeImage">
        <ImageUpdater @imageChange="imageChange" @imageChangeBin="imageChangeBin"/>
    </b-modal>

    <h4>Partenaires</h4>
        <vue-good-table styleClass="vgt-table condensed striped" max-height="500px" :columns="c_columns" :rows="c_rows">
            <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field == 'name'">{{props.row[props.column.field]}}</div>
                <div v-if="props.column.field == 'image'">
                       <img @click="clickImage(props.index,'image')" :src="props.row.image0"/>
                </div>
                <div v-if="props.column.field == 'bandeau'">
                       <img @click="clickImage(props.index,'bandeau')" :src="props.row.bandeau0"/>
                </div>
                <div v-if="props.column.field == 'isSubscribed'">
                  <input type="checkbox" v-model="props.row.isSubscribed" @change="doUpdRole2(props.row)"/>
                </div>
                <div v-if="props.column.field == 'isCircle'">
                  <input type="checkbox" v-model="props.row.isCircle" @change="doUpdRole2(props.row)"/>
                </div>
                <div v-if="props.column.field == 'url'">
                  <input type="text" v-model="props.row.url" @change="doUpdRole2(props.row)"/>
                </div>
            </template>
        </vue-good-table>
        Ajouter un client:<br/>
        nom: <input type="text" placeholder="Client" size="10" v-model="newcust.name"/><button type="button" @click="doSaveCust" class="btn btn-outline-primary buttons">{{$t('save')}}</button>

  </div>
  </div>
</template>

<script>
import MyFlashDS from "../services/MyFlashDS";
import ImageUpdater from '@/components/ImageUpdater.vue'

export default {
  data(){
      return {
        user: null,
        newcust: {},
        c_columns: [
            //{label: this.$i18n.t('id'), field: '_id',},
            {label: this.$i18n.t('name'), field: 'name',},
            {label: this.$i18n.t('logo'), field: 'image',},
            {label: this.$i18n.t('bandeau'), field: 'bandeau',},
            {label: this.$i18n.t('isSubscribed'), field: 'isSubscribed',},
            {label: this.$i18n.t('isCircle'), field: 'isCircle',},
            {label: this.$i18n.t('url'), field: 'url',},
            ],
        c_rows:[],
        idx:null, field:null, newImage: null, newImageBin: null, // changement image
      }
  },
  name: "AdminCustomers",
  //props: ['user'],
  components: {
    ImageUpdater,
  },
  methods: {
    doLoadCustomers() {
        console.log('VUE_APP_ROOT_API=', process.env.VUE_APP_ROOT_API)
        MyFlashDS.getCustomers('all').then(res => {
          res.data.forEach(x => {
            x.image0 = x.image ? process.env.VUE_APP_ROOT_API+'/..'+x.image : '/missing_image.avif';
            x.bandeau0 = x.bandeau ? process.env.VUE_APP_ROOT_API+'/..'+x.bandeau : '/missing_image.avif';
              x.isSubscribed=x.role&8;
              x.isCircle=x.role&4;
            });
          this.c_rows=res.data;
        })
    },
    doUpdRole2(prop) {
      var role=(prop.isSubscribed?8:0)+(prop.isCircle?4:0);
      console.log('ROLE', {id:prop._id, role:role})
      MyFlashDS.saveCustomer({_id:prop._id, role:role, url:prop.url}).then(() => {
        this.doLoadUsers();
      });
    },
    doSaveCust() {
      MyFlashDS.createCustomers(this.newcust).then(()=>{
        this.doLoadCustomers();
      })
    },
    clickImage(idx,field){
      this.idx=idx;
      this.field=field;
      this.$refs.imgModal.show()
    },
    imageChange(data){
      console.log('Admin,imageChange:',data);
      this.newImage=data;
    },
    imageChangeBin(data){
      console.log('Admin,imageChangeBin:',data);
      //this.newImageBin=data;
    },
    doChangeImage() {
        //this.c_rows[this.idx].image=this.newImage;
        this.c_rows[this.idx][this.field]=this.newImage;
            MyFlashDS.saveCustomer(this.c_rows[this.idx]).then(res=>{
                console.log('upload', res);
                this.doLoadCustomers();
            })
    },
  },
  mounted() {
    this.doLoadCustomers();
  },
}
</script>

<style scoped>

button {
  width: 20vh;
}

h4 {
  margin-top: 3vh;
}

img {
    max-width: 100px;
    max-height: 100px;
}

</style>

<template>
  <div class="container text-center">
   <CircleComp :text="msg" :img="circle_img"/>
   <div class="container text-start">
   <div class='config col pt-5 text-left' v-html="html"></div>
   </div>
   <div class='col'><button @click="goBack" type="button" class="text-uppercase btn btn-primary">Retour</button></div>

   <BottomBar/>

  </div>
</template>

<script>
/*
import MyFlashDS from "../services/MyFlashDS";
import UserRegister from '@/components/UserRegister.vue'
import UserLogin from '@/components/UserLogin.vue'
import WhatIsIt from '@/components/WhatIsIt.vue'
import UserView from '@/views/UserView.vue'
import NavBar from '@/components/NavBar.vue'
*/
import CircleComp from '@/components/CircleComp.vue'
import BottomBar from '@/components/BottomBar.vue'
import MyFlashDS from "../services/MyFlashDS";

export default {
  data(){
      return {
        config: null,
        msg: 'TBD',
        html: null,
        circle_img: '',
        count: 0,
      }
  },
  name: "InfoView",
  components: {
    CircleComp, BottomBar,
  },
  watch:{
      $route (){ // to, from
        //console.log('ROUTE change ', from, to)
        this.display()
      }
    },
  methods: {
    goBack() {
      window.history.go(this.count);
      this.count = 0;
      //console.log('back');
    },
    display() {
      let type = this.$route.path.substr(6);
      console.log('DISPLAY', type)
      this.msg = this.$t(type);
      this.html = this.config[type];
      this.count -= 1;
    }
  },
  mounted() {
    console.log('MOUNT InfoView', this.$route.path, this.$route.query);
      MyFlashDS.getConfig('whatisit cgu cgv mentions').then(res => {
        this.config=res.data;
        this.display()
      });

  }
}
</script>


<style scoped>

p {margin-top: -2vh !important;}

</style>

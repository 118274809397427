<template>
  <div class="container justify-content-center">

<div style="display:block">
    <NavBar :user="user"/>
</div>

<div v-if="tag" class="row limit" id="page-wrap">

   <Bandeau v-if="tag && seeprofile" :data="tag" view='public' :enableEdit='false'/>
   <PublicitePartenaire v-if="tag.customer" :tag="tag"/>
   <CircleComp v-if="!seeprofile" :text="circle_msg" :img="circle_img"/>

   <div v-if="tag.unknown" class="container-fluid p-0 text-center">
         <h3>Ce flash n'existe pas !</h3>
   </div>

   <div v-else-if="secours" class="container-fluid p-0 text-center">
     <!--<CircleComp text="Contacter"/>-->
     <div class="secours"><a href="tel:15"><img src="/contact_15.png"/></a></div>
     <div class="secours"><a class="secours" href="tel:17"><img src="/contact_17.png"/></a></div>
     <div class="secours"><a class="secours" href="tel:18"><img src="/contact_18.png"/></a></div>
     <div class="secours"><a class="secours" href="tel:112"><img src="/contact_112.png"/></a></div>
     <div class=''><button type="button" @click="secours=false" class="btn btn-outline-primary text-uppercase">{{$t('retour')}}</button></div>
   </div>

   <!-- OWNER -->
   <div id="idfff" v-else-if="owner" class="container text-center">
     <div>
         <div class="row justify-content-center">
           <div class='text-center'><button @click="seeprofile=true" v-if="!seeprofile" type="button" class="btn btn-primary text-uppercase">{{$t('seeprofile')}}</button></div>
           <UserData v-if="seeprofile" :user="owner" :view="'public'"/>
         </div>
         <div class="row justify-content-center text-center">
           <div v-if="!addcontact"><button @click="addcontact=true" type="button" class="btn btn-outline-primary text-uppercase">{{$t('addcontact')}}</button></div>
           <div v-if="addcontact"><button @click="addCircle" type="button" class="btn btn-outline-primary text-uppercase">{{$t('addcircle')}}</button></div>
           <div v-if="addcontact"><button @click="addSmart" type="button" class="btn btn-outline-primary text-uppercase">{{$t('addsmart')}}</button></div>
           <div class=''><button type="button" @click="secours=true" class="btn btn-outline-secondary text-uppercase">{{$t('secourir')}}</button></div>
         </div>

     </div>
   </div>

   <!-- pas de OWNER -->
   <div v-else class="container-fluid p-0 text-center">
     <h3>{{noowner}}</h3>
            <div v-if="user" class="row justify-content-center">
               <div class='col'><button @click="doConnect" type="button" class="btn btn-primary text-uppercase">{{$t('connectflash')}}</button></div>
            </div>
            <div v-else class="row justify-content-center">
               <div class='col'><button @click="$router.push('/?connect=yes&back=/flash/'+tag.no)" type="button" class="btn btn-primary text-uppercase">{{$t('connectflash')}}</button></div>
            </div>

            <WhatIsIt/>
   </div>

   <UserData v-if="target_user" :user="target_user" :view="'isPublic'"/>
    <BottomBar/>

</div>

  </div>
</template>

<script>
import MyFlashDS from "../services/MyFlashDS";
import NavBar from '@/components/NavBar.vue'
import Bandeau from '@/components/Bandeau.vue'
import PublicitePartenaire from '@/components/PublicitePartenaire.vue'
import UserData from '@/components/UserData.vue'
import CircleComp from '@/components/CircleComp.vue'
import WhatIsIt from '@/components/WhatIsIt.vue'
import BottomBar from '@/components/BottomBar.vue'

export default {
  data(){
      return {
        user: null, // si connecté
        tag: null, // info tag
        owner: null, noowner: " ",
        target_user: null, // tag.owner
        addcontact: false,
        seeprofile: false,
        secours: false,
        circle_msg: "", circle_img: "_text",
        connected: false,
      }
  },
  name: "FlashView",
  components: {
    NavBar,
    Bandeau, PublicitePartenaire,
    UserData, CircleComp, WhatIsIt, BottomBar
  },
  methods: {
    openTag() {
      this.$router.push('/user/public')
    },
    addCircle(){
      if (this.user) {
          MyFlashDS.saveUser({id:this.user.id, addPrivate:'+'+this.tag.owner}).then(res=>{
            this.$emit('onAlert', "info", "demande envoyée, "+res.data)
          });
      } else {
        this.$router.push('/?back=/flash/'+this.$route.params.no); // Il faut $etre connecté
      }
    },
    addSmart(){
      MyFlashDS.dataGetUser(this.tag.owner, 'data email image').then(res=>{
        console.log('dataGetUser', res.data);
        let data=res.data.data;
        console.log('data:', data)
        let vcard='BEGIN:VCARD\nVERSION:3.0\n'
        vcard+='N:'+(data.name ? data.name.value : '')+';'+(data.surname ? data.surname.value : '')+';;;\n'
        vcard+='FN:'+(data.name ? data.name.value : '')+' '+(data.surname ? data.surname.value : '')+'\n'
        //vcard+='TITLE:'+user.pseudo+'\n'
        if (data.mobile) vcard+='TEL;TYPE=work,voice;VALUE=uri:tel:'+data.mobile.value+'\n'
        if (data.tel) vcard+='TEL;TYPE=home,voice;VALUE=uri:tel:'+data.tel.value+'\n'
        if (data.company) vcard+='ORG:'+data.company.value+'\n'
        if (data.companyweb) vcard+='URL:'+data.companyweb.value+'\n'
        vcard+='EMAIL:'+res.data.email+'\n'
        vcard+='END:VCARD'
        console.log('vcard:', vcard)

        var a         = document.createElement('a');
        a.href        = 'data:text/vcard,' + encodeURIComponent(vcard);
        a.target      = '_blank';
        a.download    = 'contact.vcf';
        console.log(a.href);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    },
    doConnect(){
      MyFlashDS.saveTag({_id: this.tag._id, owner:this.user.id}).then(()=>{
          this.$emit('onAlert', "info", "Ce Flash est maintenant connecté !")
          this.circle_msg="Ce Flash a bien été ajouté sur votre compte."
          this.circle_img="_connected"
          this.doLoadTag()
      });
    },
    doLoadTag(){ // no, id, cnt
        // /flash/5000 -> no=5000, id undefined
        // /flash/50000/11223344556677 -> no=5000, id=11223344556677
        var [no, id, cnt] = [parseInt(this.$route.params.no), '', 0];
        //console.log('no=', no, 'id=', this.$route.params.id);
        if (this.$route.params.id) {
            [id, cnt] = this.$route.params.id.split('x');
            cnt = (cnt==undefined) ? 0 : parseInt(cnt,16);
        }
        MyFlashDS.findTag({no, id, cnt}).then(res=>{
          console.log('<MyFlashDS.dataGetFlash', res.data, res.data.owner);
          if (!res.data) res.data={unknown:true}
          this.tag=res.data;
          //this.target_user={id: res.data.owner};
          if (this.tag.owner) {
            MyFlashDS.dataGetUser(this.tag.owner, 'id pseudo').then(res=>{this.owner=res.data});
          } else {
            this.noowner="Ce flash n'est relié à aucun utilisateur !"
          }
          if (this.$route.query.connect=='yes' && !this.connected) {
            console.log('CONNECT auto !')
            this.connected=true;
            this.doConnect(); // Connection si demandé
            }
        });
    },
  },
  mounted() {
    console.log('MOUNT FlashView, params:', this.$route.params);
    MyFlashDS.identifyUser().then(res=>{
        this.user=res.data;
        // N° tag : this.$route.params.no
        this.doLoadTag(); // no, id, cnt
    });
  }
}
</script>


<style scoped>

div.secours {}
div.secours a:link {font-size: 200%; color:black;}
div.secours a img {margin-right: 50px}

div.block {
  color : red;
}

img.img_access {
  width: 15px; height: 15px; margin-bottom: 0.8vh;
}


input.chk {
  width: 20px;
}

button {
    cursor: pointer;
    width: 30vh;
    margin-top: 2vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-size: 1rem;
    font-family: ITCAvantGarde, serif;
}

img.logo {
    object-fit: cover;
    height: 40vh;
}

input {
    border: 1px solid var(--couleur-principale);
    width: 45vh;
}
</style>

<template>
  <div class="container justify-content-center">

<!--
<div class="container" style="border: medium;">
   <div class="row pt-2 justify-content-center">
      <div class="card card-outline-secondary col-sm-11 col-md-11 col-lg-8" style="border: 2px solid rgb(238, 238, 238); box-shadow: rgb(204, 204, 204) 0px 2px 3px; padding: 7vh;">
         <div>
            <h5 class="mb-0">Changer de mot de passe</h5>
         </div>
         <div class="card-body pl-0 pr-0">
            <form class="form" autocomplete="off">
               <div class="form-group"><label for="password" class="inputGroup mb-3 col-12"><input type="password" name="password" autocomplete="off" aria-label="password" class="form-control form-control-lg" placeholder="Mot de passe " value=""></label></div>
               <div class="form-group"><label for="inputPasswordNew">Nouveau mot de passe</label><label for="password" class="inputGroup mb-3 col-12"><input type="password" name="newPassword" autocomplete="off" aria-label="password" class="form-control form-control-lg" placeholder="Nouveau mot de passe " value=""></label><label for="cpassword" class="inputGroup mb-3 col-12"><input type="password" name="password_confirm" autocomplete="off" aria-label="password_confirm" class="form-control form-control-lg" placeholder="Confirmer nouveau mot de passe " value=""></label></div>
               <div class="form-group"><button type="submit" class="btn btn-primary col-12 col-md-6 col-lg-4 float-right">Confirmer nouveau mot de passe</button></div>
            </form>
         </div>
      </div>
      <div class="container mb-5" style="border: medium;">
         <div class="row pt-5 justify-content-center">
            <div class="card card-outline-secondary col-sm-11 col-md-11 col-lg-8" style="border: 2px solid rgb(238, 238, 238); box-shadow: rgb(204, 204, 204) 0px 2px 3px; padding: 7vh;">
               <div class="mb-5">
                  <h5 class="mb-4">Changer de mail ou de pseudo</h5>
               </div>
               <div class="card-body pl-0 pr-0">
                  <form class="form" autocomplete="off">
                     <div class="form-group"><label for="email" class="inputGroup mb-3 col-12">Email:</label><input type="text" name="email" autocomplete="off" aria-label="email" class="form-control form-control-lg" placeholder="email" value="emmanuel.goudot@free.fr"></div>
                     <div class="form-group"><label for="pseudo" class="inputGroup mb-3 col-12">Pseudo:</label><input type="text" name="pseudo" autocomplete="off" aria-label="pseudo" class="form-control form-control-lg" placeholder="pseudo" value="FREE"></div>
                     <div class="form-group"><button type="submit" class="btn btn-primary col-12 col-md-6 col-lg-4 float-right">Confirmer</button></div>
                  </form>
               </div>
            </div>
         </div>
      </div>
      <div class="container mb-5" style="border: medium;">
         <div class="row pt-5 justify-content-center">
            <div class="card card-outline-secondary col-sm-11 col-md-11 col-lg-8" style="border: 2px solid rgb(238, 238, 238); box-shadow: rgb(204, 204, 204) 0px 2px 3px; padding: 7vh;">
               <div class="mb-5">
                  <h5 class="mb-4">Suppression du compte</h5>
                  <p>Voulez-vous supprimer ce compte ?</p>
               </div>
               <div class="pr-2"><button type="submit" data-toggle="modal" data-target="#exampleModalCenter" class="btn btn-danger col-12 col-md-6 col-lg-4 float-right">Supprimer ce compte ?</button></div>
            </div>
         </div>
      </div>
   </div>
</div>
-->
</div>
</template>

<script>
//import MyFlashDS from "../services/MyFlashDS";

export default {
  data(){
      return {
        user: null,
        data: null,
      }
  },
  name: "UserAdminView",
  //props: ['user'],
  components: {
  },
  methods: {
  },
  mounted() {
/*
    MyFlashDS.identifyUser().then(res=>{
        console.log('<MyFlashDS.identifyUser>', res.data);
        this.user=res.data;
        this.data={customer: null, owner:this.user.id}
        });
*/
  }
}
</script>


<style scoped>
</style>

<template>
  <div class="container justify-content-center">

      <b-modal id="modal-cgu" ref="cgu" size="xl" scrollable title="Conditions Générales">
        <div v-html="cgu"/>
      </b-modal>


    <div>
        <CircleComp :text="$t('inscription')" v-if="!user.forgotten"/>
        <CircleComp :text="$t('reinit')" v-if="user.forgotten"/>


        <!--<div>user: {{user}}</div>-->
        <input type='hidden' v-model="user.forgotten" />

        <div class="row justify-content-center md-form md-outline" v-if="!user.forgotten">
            <div class="error" >{{error.pseudo}}</div>
        </div>
        <div class="row justify-content-center md-form md-outline">
          <span>
            <input @change="myChange" @blur="myChange" v-model="user.pseudo" :placeholder="$t('pseudo')" />
            <i class="here bi bi-eye white"></i>
          </span>
        </div>
        <div class="row justify-content-center md-form md-outline">
            <div class="error" >{{error.email}}</div>
        </div>
        <div class="row justify-content-center md-form md-outline">
          <span>
            <input type="email" @change="myChange" @blur="myChange" v-model="user.email" :placeholder="$t('email')" :readonly="user.forgotten"/>
            <i class="here bi bi-eye white"></i>
          </span>
        </div>
        <div class="row justify-content-center md-form md-outline">
            <div class="error" >{{error.password}}</div>
        </div>
        <div class="row justify-content-center md-form md-outline">
          <span>
            <input @change="myChange" @blur="myChange" :type="show_pass ? 'text':'password'" v-model="user.password" :placeholder="$t('password')" />
            <i v-if='show_pass' @click='show_pass=!show_pass' class="here bi bi-eye-slash"/>
            <i v-if='!show_pass' @click='show_pass=!show_pass' class="here bi bi-eye "/>
          </span>
        </div>
        <div class="row justify-content-center md-form md-outline">
            <div class="error" >{{error.password2}}</div>
        </div>
        <div class="row justify-content-center md-form md-outline">
          <span>
            <input @change="myChange" @blur="myChange" :type="show_pass2 ? 'text':'password'" v-model="user.password2" :placeholder="$t('confirmpassword')" />
            <i v-if='show_pass2' @click='show_pass2=!show_pass2' class="here bi bi-eye-slash"/>
            <i v-if='!show_pass2' @click='show_pass2=!show_pass2' class="here bi bi-eye"/>
          </span>
        </div>
        <div class="row justify-content-center">
            <input style="accent-color: var(--couleur-principale);" @change="myChange" @blur="myChange" v-model="user.validation" type="checkbox" />{{$t('validation')}} <a @click="openModal()" href="#">{{$t('cgu')}}</a>
        </div>
        <div class="row justify-content-center">
            <div class="error" style="text-align: center !important;" >{{error.global}}</div>
           <div class=''><button @click="doValidate()" type="button" class="btn btn-primary buttons text-uppercase">{{$t('validate')}}</button></div>
        </div>
        <div class="row justify-content-center">
           <div class=''><button @click="doReturn()" type="button" class="btn btn-outline-primary buttons text-uppercase">{{$t('return')}}</button></div>
        </div>
    </div>
  </div>
</template>

<script>
import MyFlashDS from "../services/MyFlashDS";
import CircleComp from '@/components/CircleComp.vue'

export default {
  data(){
      return {
        user: {forgotten:null, email:'', password:''},
        //forgotten: false,
        cgu: '<b>TBD</b>...',
        error: {},
        show_pass: false, show_pass2: false,
      }
  },
  name: "UserRegister",
  components: {
    CircleComp,
  },
  methods: {
    doValidate(){
      if (this.error.global) {
        alert(this.error.global);
        return;
      }
      MyFlashDS.registerUser(this.user).then(res=>{
        console.log(res)
        if (res.data.error) {
          this.error = res.data.error;
        } else {
          this.$emit('onAlert', "success", "Vous vous êtes bien enregistré");
          this.$emit('doReturn', "OK");
        }
      });
    },
    doReturn(){
      this.$emit('doReturn', "OK");
    },
    myChange() {
      this.$set(this.error, 'pseudo', this.user.pseudo ? null : 'pseudo ne peut être vide');
      this.$set(this.error, 'email', this.user.email ? (this.user.email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ? null : 'email invalid') : 'email ne peut être vide');
      this.$set(this.error, 'password', this.user.password ? null : 'password ne peut être vide');
      this.$set(this.error, 'password2', this.user.password2 ? (this.user.password==this.user.password2 ? null : 'les mots de passe ne correspondent pas') : 'password ne peut être vide');
      this.$set(this.error, 'global', this.error.pseudo || this.error.email || this.error.password || this.error.password2 || (this.user.validation ? null : "vous devez valider les conditions générales d'utilisation."));
      //console.log('VALID', this.error)
    },
    openModal() {
      MyFlashDS.getConfig('cgu').then(res => {
        this.cgu=res.data.cgu;
        this.$refs.cgu.show();
      });
    },
  },
  mounted() {
    if (this.$route.query.forgotten) {
        this.user.forgotten=this.$route.query.forgotten;
        this.user.email=this.$route.query.email;
        this.user.pseudo='***'
    }
    console.log('MOUNT UserRegister', this.$route.path, this.$route.query, this.user);
  }
}
</script>


<style scoped>

.password-toggle-icon {
  position: absolute;
  right: -20vh;
  transform: translateY(-0px);
  cursor: pointer;
  font-size: 125%;
}

i.here {
  margin-left: -20px;
  cursor: pointer;
}
.noicon {margin-left: 1vw}

.error {
  color: red;
  font-size: 80%;
  text-align: start !important;
  min-height: 3vh;
}

button {
    cursor: pointer;
    width: 30vh;
    margin-top: 2vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-size: 1rem;
    font-family: ITCAvantGarde, serif;
}

img.logo {
    object-fit: cover;
    height: 40vh;
}

input {border: 1px solid var(--couleur-principale);}

@media (min-width: 1200px) {
  input { width: 50vw;}
  .error { width: 50vw;}
}
@media (max-width: 1200px) {
  input { width: 75vw;}
  .error { width: 75vw;}
}

input[type='checkbox'] {
    width: 3vh;
}

.white {opacity: 0;}


</style>

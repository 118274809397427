<template>
  <div class="container justify-content-center" style="padding-left: 0">
  <div v-if="u_rows">

    <h4>Utilisateurs</h4>
        <vue-good-table styleClass="vgt-table condensed striped" max-height="500px" :columns="u_columns" :rows="u_rows">
            <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field == 'isAdmin'">
                  <input type="checkbox" v-model="props.row.isAdmin" @change="doUpdRole2(props.row)"/>
                </div>
                <div v-if="props.column.field == 'isSecurity'">
                  <input type="checkbox" v-model="props.row.isSecurity" @change="doUpdRole2(props.row)"/>
                </div>
                <div v-if="props.column.field == 'isSubscribed'">
                  <input type="checkbox" v-model="props.row.isSubscribed" @change="doUpdRole2(props.row)"/>
                </div>
                <div v-if="props.column.field == 'isCircle'">
                  <input type="checkbox" v-model="props.row.isCircle" @change="doUpdRole2(props.row)"/>
                </div>
                <div v-if="props.column.field == 'isValidated'">
                  <input type="checkbox" v-model="props.row.isValidated" @change="doUpdRole2(props.row)"/>
                </div>
                <div v-if="props.column.field == 'pseudo'">{{props.row[props.column.field]}}</div>
                <div v-if="props.column.field == 'tags'">{{props.row[props.column.field]}}</div>
                <div v-if="props.column.field == 'createdAt'">{{props.formattedRow[props.column.field].substring(0,10)}}</div>
            </template>
        </vue-good-table>

  </div>
  </div>
</template>

<script>
import MyFlashDS from "../services/MyFlashDS";
//import ImageUpdater from '@/components/ImageUpdater.vue'

export default {
  data(){
      return {
        user: null,
        u_columns: [
            {label: this.$i18n.t('pseudo'), field: 'pseudo',},
            {label: this.$i18n.t('tags'), field: 'tags',},
            {label: this.$i18n.t('isAdmin'), field: 'isAdmin',},
            {label: this.$i18n.t('isSecurity'), field: 'isSecurity',},
            {label: this.$i18n.t('isSubscribed'), field: 'isSubscribed',},
            {label: this.$i18n.t('isCircle'), field: 'isCircle',},
            {label: this.$i18n.t('isValidated'), field: 'isValidated',},
            {label: this.$i18n.t('createdAt'), field: 'createdAt',},
            ],
        u_rows: [],
      }
  },
  name: "AdminUsers",
  //props: ['user'],
  components: {
    //ImageUpdater,
  },
  methods: {
    doLoadUsers() {
        MyFlashDS.getAllUsers().then(res => {
            res.data.forEach(x=>{
              x.isAdmin=x.role&1;
              x.isSecurity=x.role&2;
              x.isCircle=x.role&4;
              x.isSubscribed=x.role&8;
              x.isValidated=x.role&16;
            });
            this.u_rows=res.data;
        });
    },
    doUpdRole2(prop) {
      var role=(prop.isAdmin?1:0)+(prop.isSecurity?2:0)+(prop.isCircle?4:0)+(prop.isSubscribed?8:0)+(prop.isValidated?16:0);
      console.log('ROLE', {id:prop._id, role:role})
      MyFlashDS.saveUser({id:prop._id, role:role}).then(() => {
        this.doLoadUsers();
      });
    },
  },
  mounted() {
    this.doLoadUsers();
  },
}
</script>

<style scoped>

button {
  width: 20vh;
}

h4 {
  margin-top: 3vh;
}

img {
    max-width: 100px;
    max-height: 100px;
}

</style>

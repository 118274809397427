<template>
  <div id="app">

      <div id="alert-container" class="position-fixed bottom-0 end-0 p-3" style="z-index:1">
          <b-alert v-for="(alert,ia) in alerts" :key="ia" :variant="alert.variant" show=3>{{alert.msg}}</b-alert>
      </div>

    <div class="container">

        <router-view @onAlert="onAlert"/>
    </div>
  </div>
</template>

<style>
@font-face {
  font-family: 'ITCAvantGarde';
  src: local('ITCAvantGarde'), url(fonts/ITCAvantGardeStd-Bk_0.ttf) format('truetype');
}

@font-face {
  font-family: 'ITCAvantGardeBold';
  src: local('ITCAvantGarde'), url(fonts/ITCAvantGardeStd-Bold_0.ttf) format('truetype');
}

@font-face {
  font-family: 'ITCAvantGardeDemi';
  src: local('ITCAvantGarde'), url(fonts/ITCAvantGardeStd-Demi_0.ttf) format('truetype');
}

@font-face {
  font-family: 'ITCAvantGardeBook';
  src: local('ITCAvantGarde'), url(fonts/ITCAvantGardeStd-Bk_0.ttf) format('truetype');
}

:root {
  --couleur-principale: #0086cd;
  --couleur-secondaire: #87bfe9;
  /*
  --couleur-tertiaire: #96e8b5;
  --couleur-texte: #444444;
  */
}

/* NO 1 */
.btn-primary, .btn-primary:active, .btn-primary:visited {
    background-color: var(--couleur-principale) !important;
    border-color: var(--couleur-principale) !important;
    font-weight: bold;
}
.btn-primary:hover {
    background-color: var(--couleur-secondaire) !important;
    border-color: var(--couleur-secondaire) !important;
    font-weight: bold;
}

/* NO 2 */
.btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary:visited {
    border-color: var(--couleur-principale) !important;
    color: var(--couleur-principale) !important;
    font-weight: bold;
}
.btn-outline-primary:hover{
    background-color: var(--couleur-secondaire) !important;
    border-color: var(--couleur-secondaire) !important;
    color: white !important;
    font-weight: bold;
}

/* NO 3 */
.btn-outline-secondary{
    border-color: var(--couleur-secondaire) !important;
    color: var(--couleur-secondaire) !important;
    font-weight: bold;
}
.btn-outline-secondary:hover{
    background-color: var(--couleur-secondaire) !important;
    border-color: var(--couleur-secondaire) !important;
    color: white !important;
    font-weight: bold;
}

/* not used */
.btn-secondary, .btn-secondary:hover, .btn-secondary:active, .btn-secondary:visited {
    background-color: var(--couleur-secondaire) !important;
    border-color: var(--couleur-secondaire);
}


#alert-container {
  margin-bottom: 100px;
}

container: {
    height: "90vh";
    display: "flex";
    alignItems: "center";
    textAlign: "center";
    marginBottom: '10vh';
  }
</style>

<script>
//import BottomBar from '@/components/BottomBar.vue'
//import MainMenu from '@/components/MainMenu.vue'


export default {
  name: "app",
  data() {
    return {
      alerts: [],
    }
  },
  components: {
      //BottomBar,
      //MainMenu,
  },
  methods: {
    onAlert(variant, message) {
              this.alerts.push({msg: message, variant:variant})
    }
  },
  mounted() {
    this.onAlert("info", this.$i18n.t('gomyflash'));
  }
};
</script>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './plugins/i18n';
import { BootstrapVue, IconsPlugin, AlertPlugin} from 'bootstrap-vue'
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueGoodTable from 'vue-good-table';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-icons/font/bootstrap-icons.css';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import 'vue-good-table/dist/vue-good-table.css'

/*
const express = require('express');
const path = require('path');
const app = express();
app.use(express.static(path.join(__dirname, 'dist')));
*/
axios.defaults.withCredentials = true;
Vue.config.productionTip = false

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(AlertPlugin)
Vue.use(VueGoodTable);

new Vue({
  router,
  i18n,
  BootstrapVue,
  IconsPlugin,
  render: h => h(App),
}).$mount('#app')
Vue.use(VueAxios, axios);

<template>
  <div class="container justify-content-center">

    <b-modal ref="imgModal" id="imgModal" @ok="doChangeImage">
        <ImageUpdater @imageChange="imageChange"/>
    </b-modal>

    <NavBar :user="user" @setView="setView" submenu="true"/>
    <Bandeau v-if="data" :data="data" :enableEdit='true'/>

    <UserContacts v-if="$route.params.type=='contacts'"/>
    <UserFlashs v-if="$route.params.type=='flashs'"/>
    <UserAdmin/>

    <BottomBar/>

</div>
</template>

<script>
import MyFlashDS from "../services/MyFlashDS";
import NavBar from '@/components/NavBar.vue'
import Bandeau from '@/components/Bandeau.vue'
import UserContacts from '@/components/UserContacts.vue'
import UserFlashs from '@/components/UserFlashs.vue'
import UserAdmin from '@/components/UserAdmin.vue'
import BottomBar from '@/components/BottomBar.vue'

export default {
  data(){
      return {
        user: null,
        data: null,
      }
  },
  name: "UserAdminView",
  //props: ['user'],
  components: {
    NavBar, Bandeau, UserContacts, UserFlashs, UserAdmin, BottomBar,
  },
  methods: {
    doChangeImage(){}, // TODO
    imageChange(){}, // TODO
    setView(){}, // TODO
  },
  mounted() {
    MyFlashDS.identifyUser().then(res=>{
        console.log('<MyFlashDS.identifyUser>', res.data);
        this.user=res.data;
        this.data={customer: null, owner:this.user.id}
        });
  }
}
</script>


<style scoped>
</style>

import Vue from "vue";
import Router from "vue-router";
//import { createRouter, createWebHistory } from 'vue-router'

import AdminView from '@/views/AdminView.vue'
import MainView from '@/views/MainView.vue'
import UserView from '@/views/UserView.vue'
import UserAdminView from '@/views/UserAdminView.vue'
import UserValidationView from '@/views/UserValidationView.vue'
import FlashView from '@/views/FlashView.vue'
//import TestComp from '@/views/TestComp.vue'
import InfoView from '@/views/InfoView.vue'
import UtfView from '@/views/UtfView.vue'


console.log('ROUTER', Router)
Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      alias: "/logout",
      name: "main",
      component: MainView,
    },
    {
      path: "/admin",
      name: "Administration",
      component: AdminView,
    },
    {
      path: "/user",
      name: "User1",
      component: UserView,
    },
    {
      path: "/user/:type",
      name: "User2",
      component: UserView,
    },
    {
      path: "/contacts/:type", // type : contacts / flashs
      name: "Contacts",
      component: UserAdminView,
    },
    {
      path: "/info/:type", // type : CGU/whatisit...
      name: "info",
      component: InfoView,
    },
    {
      path: "/validate/",
      name: "Validation",
      component: UserValidationView,
    },
/*
    {
      path: "/test/",
      name: "Tests",
      component: TestComp,
    },
*/
    {
      path: "/flash/:no",
      name: "Flash-detail",
      component: FlashView,
    },
    // 2024-02 modif RS
    {
      path: "/flash/:no/:id",
      name: "Flash-detail",
      component: FlashView,
    },
    {
      path: "/utf8",
      name: "UTF detail",
      component: UtfView,
    },
  ]
});

<template>
<div>

    <Slide right :closeOnNavigation="true" @closeMenu="handleCloseMenu">
    <nav v-if="true" class="nav justify-content-start">
          <div class="nav-item justify-content-start" >

          <div v-if="user" class="container text-center">
          <div class="row">
              <div class="col">
                    <img v-if="image" :src="image" alt="Profil0" class="rounded-circle cover" style="height: 140px; width:140px;"/>
              </div>
          </div>
          <div class="row">
              <div class="col" style="color: #00afef">
                  {{user.pseudo}}
              </div>
          </div>
          </div>

            <hr/>
            <div v-if="user">
            <div class="head">My PROFILE</div>
            <div class="small"><router-link class="alink" to="/user/public">{{$t('public')}}</router-link></div>
            <div class="small"><router-link class="alink" to="/user/private">{{$t('private')}}</router-link></div>
            <div class="small"><router-link class="alink" to="/user/security">{{$t('security')}}</router-link></div>
            <div class="small" @click="getView(null)"><router-link class="alink2" to="/user">{{$t('modify')}}</router-link></div>
            <br/>
            <div class="head">My CIRCLE</div>
            <div class="small"><router-link class="alink" to="/contacts/contacts">{{$t('contacts')}}</router-link></div>
            <br/>
            <div class="head">My FLASH</div>
            <div class="small"><router-link class="alink" to="/contacts/flashs">{{$t('flashs')}}</router-link></div>
            <div class="small"><a target="shop" class="alink" href="https://www.my-flash.fr/">{{$t('shop')}}</a></div>
            <br/>
            <div class="head" v-if="user.role==1"><router-link to="/admin">{{$t('administration')}}</router-link><br/><br/></div>
            </div>

            <div class="head" v-if="user"><router-link class="link" to="/logout">ME DÉCONNECTER</router-link></div>
            <div class="head" v-if="!user"><router-link class="link" to="/?isConnecting=true">ME CONNECTER</router-link></div>
            <div class="head" v-if="!user"><router-link class="link" to="/?isRegistering=true">M'INSCRIRE</router-link></div>
          </div>

          <br/><br/>

    </nav>

    </Slide>

</div>
</template>

<script>
import MyFlashDS from "../services/MyFlashDS";
import { Slide } from 'vue-burger-menu'  // import the CSS transitions you wish to use, in this case we are using `Slide`

export default {
  name: "NavBar",
  props: ['user'],
  watch: {
    user: function(){
        console.log('NAV USER', this.user)
        MyFlashDS.dataGetUser(this.user.id, 'image avatar').then(res=>{
            this.image=process.env.VUE_APP_ROOT_API+'/..'+res.data.image
        });
    },
  },
  data() {
    return {
        image: null,
    };
  },
  components: {Slide},
  methods: {
    getView(view) {
        console.log('NavBar,VIEW', view);
        this.$emit('setView', view);
    },
    handleCloseMenu(){
        console.log('close menu')
        },
  },
  mounted() {
  }
};
</script>

<style>
  .bm-burger-button {z-index: 3;}
  .bm-burger-bars {
    background-color: white !important;
    border: solid 1px black;
  }
</style>

<style scoped>

.link {
  color: #b2b1b2;
}
.link:hover {
  color: #666365;
}

.alink:hover {
  color: #666365;
}

.alink2 {color: #0086cd;}
.alink2:hover {color: #87bfe9;}

nav {
    display: block;
    text-align: left;
}

img.cover {
  object-fit: cover;
}

div.head {color: white;}
div.small {color: white; font-size: 80%}
div.pseudo {color: #00afef; text-align: center;}

a {
    color: white;
    text-decoration: none;
}

</style>

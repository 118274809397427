<template>
  <div class="container justify-content-center">

  <h5 class="mb-4">Mes flashs :</h5>

            <table class="table">
            <thead>
                <tr>
                  <th scope="col">n°</th>
                  <th scope="col">libérer</th>
                  <th scope="col">Partenaire</th>
                  <th scope="col">Date</th>
                </tr>
              </thead>
              <tbody>
                  <tr v-for="(tag, index) in tags" :key="index">
                    <th scope="row">{{tag.no}}</th>
                    <td><button :data-_id="tag._id" type="button" @click="freeFlash" class="btn btn-outline-primary buttons">{{$t('freeflasbutton')}}</button></td>
                    <td>{{tag.customer}}</td>
                    <td>{{tag.createdAt.substr(0,10)}}</td>
                  </tr>
                </tbody>
            </table>

<!--
<div class="container mb-5" style="border: medium;">
   <div class="row pt-5 justify-content-center">
      <div class="card card-outline-secondary" style="border: 2px solid rgb(238, 238, 238); box-shadow: rgb(204, 204, 204) 0px 2px 3px; padding: 7vh;">
         <div class="mb-5">
            <h5 class="mb-4">Mes flashs :</h5>

            <vue-good-table styleClass="vgt-table condensed striped" max-height="500px" :columns="t_columns" :rows="tags">
                <template slot="table-row" slot-scope="props">
                  <div v-if="props.column.field == 'free'"><button :data-_id="props.row._id" type="button" @click="freeFlash" class="btn btn-outline-primary buttons">{{$t('freeflasbutton')}}</button></div>
                </template>
            </vue-good-table>
         </div>

      </div>
   </div>
</div>
-->

</div>
</template>

<script>
import MyFlashDS from "../services/MyFlashDS";

export default {
  data(){
      return {
        user: null,
        tags: null,
        t_columns: [
            {label: this.$i18n.t('no'), field: 'no',},
            {label: this.$i18n.t('customer'), field: 'customer',},
            {label: this.$i18n.t('createdAt'), field: 'createdAt',},
            {label: this.$i18n.t('freeflash'), field: 'free',},
            ],

      }
  },
  name: "UserFlash",
  //props: ['user'],
  components: {
  },
  methods: {
    loadFlashs(){
        MyFlashDS.getTags().then(res=>{
          res.data.map(x=>{x.free=1})
          this.tags=res.data;
        })
    },
    freeFlash(evt) {
      if (confirm(this.$i18n.t('confirmFlashForget'))) {
        let data={_id: evt.target.dataset._id, owner: null}
        console.log('OK', data)
        MyFlashDS.saveTag(data).then(()=>{
          this.loadFlashs();
        })
      }
    },
  },
  mounted() {
    MyFlashDS.identifyUser().then(res=>{
        console.log('<MyFlashDS.identifyUser>', res.data);
        this.user=res.data;
        //this.data={customer: null, owner:this.user.id}
        this.loadFlashs()
    });
  }
}
</script>


<style scoped>
</style>

/*

Vue : {{ $route.params.type }}
*/


<template>
  <div v-if="user && data" class="container justify-content-center">
  <div v-if="$route.params.type" class="tprofil">profil {{$t($route.params.type)}}</div>

<div class="mb-2">
   <div v-if="!view" class="card story-card mb-3">
      <div>
        <div class="label-header">
            <div class="icon-label">
              <div class='b1'>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;En cochant la case,</div>
                <div>vos informations seront</div>
                <div>visibles dans les profils :</div>
                <hr/>
                <div>Public&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                Privé&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                Secours</div>
              </div>
              <div class='b2'>
                <img src="/PUBLIC.png" :title="$t('public_vue')" style="width: 1.5rem; height: 1.5rem; margin-bottom: 0.8vh;">
                <img src="/PRIVATE.png" :title="$t('private_vue')" style="width: 1.5rem; height: 1.5rem; margin-bottom: 0.8vh;">
                <img src="/SECURITY.png" :title="$t('security_vue')" style="width: 1.5rem; height: 1.5rem; margin-bottom: 0.8vh;">
              </div>
            </div>
        </div>
      </div>

   </div>
</div>


<div v-for="(block,idb) in blocks" :key="idb" :id="block.id" class="mb-2">
   <div class="card story-card mb-3">
      <div class="card-header pt-2 pb-2 container-fluid" :style="'border-top: 5px solid '+block.color">
        <div class="col-md-10">
         <h6 class="card-title d-flex justify-content-start">{{block.name}}</h6>
         <a :ref="'A'+idb" :href="'#block'+idb" data-bs-toggle="collapse" class="mycollapse btn mt-3 mr-2" @click="collapseChange(idb)">➖</a>
        </div>
      </div>

      <div :class="'collapse show '+(view ? 'block'+idb : '')" :id="'block'+idb"> <!-- TODO block4 : Réseaux peux changer ! -->
      <div v-for="(field,idf) in block.fields" :key="idf" > <!-- class="body-section mb-3" -->

        <div v-if="view" class="label-header d-flex justify-content-start"> <!-- vue  -->
          <!-- {{field.id}} : {{data[field.id]}}, {{flagview}} -->
          <div v-if="data[field.id].value && data[field.id].access&flagview">
            <div v-if="field.type=='link'" class="px-3">
                <a :href="data[field.id].value" target='link'><img :src="'/'+field.logo" class="network"/></a> {{field.id}}
            </div>
            <div v-else>
                <div class="px-3 dataLabel d-flex justify-content-start">{{field.name}}</div>
                <div class="px-3 dataView d-flex justify-content-start">{{data[field.id].value}}</div>
            </div>
          </div>
        </div>

        <div v-else class="label-header"> <!-- edition  -->
            <img v-if="field.logo" :src="'/'+field.logo" class="network"/>{{field.name}}
            <div v-if="!view" class="icon-label">
                <img src="/PUBLIC.png" :title="$t('public_vue')" style="width: 1.5rem; height: 1.5rem; margin-bottom: 0.8vh;"> <input type="checkbox" @change="doCheck" :data-field="field.id" data-power=1 :checked="data[field.id].access & 1">,
                <img src="/PRIVATE.png" :title="$t('private_vue')" style="width: 1.5rem; height: 1.5rem; margin-bottom: 0.8vh;"> <input type="checkbox" @change="doCheck" :data-field="field.id" data-power=2 :checked="data[field.id].access & 2">,
                <img src="/SECURITY.png" :title="$t('security_vue')" style="width: 1.5rem; height: 1.5rem; margin-bottom: 0.8vh;"> <input type="checkbox" @change="doCheck" :data-field="field.id" data-power=4 :checked="data[field.id].access & 4">
            </div>
            <input v-if="field.type=='text' || field.type=='link'" type="text" class="section-content m-0" style="width: 100%;" v-model="data[field.id].value">
            <input v-if="field.type=='date'" type="date" class="section-content m-0" style="width: 100%;" v-model="data[field.id].value">
            <select v-if="false && field.type=='list'" class="section-content m-0" style="width: 100%;" multiple v-model="data[field.id].value">
                <option v-for="(choix,idc) in field.values" :key="idc">{{choix}}</option>
            </select>
            <div v-if="field.type=='list'">
            <!--value={{data[field.id].value}}<br/>-->

            <div v-for="(choix,idc) in field.values" :key="idc">
                <input v-if="data[field.id].value.includes(choix)" class="choices" type="checkbox" :id="field.id+idc" checked :value="choix" @click="listChg(field.id, idc, choix)">
                <input v-else class="choices" type="checkbox" :id="field.id+idc" :value="choix" @click="listChg(field.id, idc, choix)">
                <label :for="field.id+idc">{{choix}}</label>
            </div>

            </div>


        </div>

      </div>
      </div>
   </div>
</div>

            <div v-if="!view" class="row justify-content-center">
               <button type="button" @click="doSave" class="btn btn-outline-primary buttons">{{$t('save')}}</button>
            </div>
            <div v-if="!view" class="row justify-content-center">
               <button type="button" @click="doDelete" class="btn btn-outline-danger buttons">{{$t('deleteUser')}}</button>
            </div>

  </div>
</template>

<script>
import MyFlashDS from "../services/MyFlashDS";

export default {
  data(){
      return {
        data:null,
        flagview:null,
        blocks:[
          {id:'b_presentation', color:'#FFFFFF', fields:['presentation']},
          {id:'b_identity', color:'#00B5EC', fields:['name', 'surname', 'birthdate', 'birthplace']},
          {id:'b_coord', color:'#32B92D', fields:['address', 'cp', 'town']},
          {id:'b_network', color:'#8E44AD', fields:['facebook', 'instagram', 'twitter']},
          {id:'b_interest', color:'#7B8D8E', fields:['films', 'books', 'series']},
          {id:'b_sante', color:'#00AAA0', fields:['allergy', 'treatment', 'operation']},
          {id:'b_professionel', color:'#C82333', fields:['job', 'company', 'lastexp']},
        ],
      }
  },
  name: "UserData",
  props: ['user', 'view'],
  components: {
  },
  watch: {
    view(nv) {
        if (nv=='public') this.flagview=1;
        if (nv=='private') this.flagview=2;
        if (nv=='security') this.flagview=4;
        if (nv=='edit') this.flagview=0;
    },
  },
  methods: {
    listChg(field, idc, choix) {
      console.log(field, idc, this.data[field].value, choix, this.$refs[field+idc])
      let idx=this.data[field].value.indexOf(choix)
      console.log('idx=', idx)
      if (idx>=0) {this.data[field].value.splice(idx,1)}
      else {this.data[field].value.push(choix)}
      console.log('idx=', idx, this.data[field].value)
      //this.data[field].push(55)
    },
    collapseChange(idb){
        var ref = this.$refs['A'+idb][0]
        ref.innerHTML = ref.innerHTML=='➕' ? '➖' : '➕';
        console.log('collapseChange', idb, ref, ref.text)
    },
    doSave() {
      console.log('doSave', this.data);
      MyFlashDS.saveUser({id: this.user.id, data: this.data});
    },
    doDelete() {
      if (confirm(this.$i18n.t('confirmUserForget'))) {
        MyFlashDS.deleteUser();
        this.$router.push('/logout')
      }
    },
    doCheck(evt) {
      var target=evt.target;
      var power=parseInt(target.dataset.power);
      this.data[target.dataset.field].access = this.data[target.dataset.field].access + (target.checked ? power : -power)
      console.log('doCheck:', target.dataset.field, power, target.checked, this.data[target.dataset.field].access);
    },
    setView(view) {
        this.view=view;
    }
  },
  mounted() {
    //alert('view:'+this.view);
    console.log('MOUNT UserData', this.view, 'user:', this.user.id);
    console.log('route', this.$route.query, this.$route.params);

        if (this.view=='public') this.flagview=1;
        if (this.view=='private') this.flagview=2;
        if (this.view=='security') this.flagview=4;
        if (this.view=='edit') this.flagview=0;

    MyFlashDS.getConfig('profils').then(res => {
      console.log('profils:', res.data.profils);
      this.blocks=res.data.profils;

      if (this.user.id) {
        MyFlashDS.dataGetUser(this.user.id, 'data email pseudo').then(res=>{
          console.log('<MyFlashDS.dataGetUser', res.data);
          this.data = res.data.data;
          if (this.data.length>=0) this.data={};
          this.blocks.forEach(block => {
            //console.log('block', block.name)
            block.fields.forEach(field => {
              //console.log('  '+field.id+':'+field.name+', '+field.type+'='+this.data[field.id])
              if (!(field.id in this.data)) this.data[field.id]={value:'', access:0} // isPublic: false, isPrivate: false, isSecurity: false
              if (field.type=='list') {
                if (!Array.isArray(this.data[field.id].value)) this.data[field.id].value=[]
              }
              //console.log('  '+field.id+':'+field.name+', '+field.type+'='+this.data[field.id].value)
            })
          });
        })
      }

    })



  }
}
</script>


<style scoped>

.tprofil {
  color: gray
}

div.b1 div {font-size: 80%}
div.b2 {margin-right: -6px}
div.b2 img {margin-right: 31px}

/* réseaux sociaux en ligne */
.block3removed {display : flex;}
.block3 a {margin-right : 5px;}

input.choices {width: 20px}

img.network {width: 5vh; margin-bottom: 7mm;}
.mycollapse {position: absolute; top: -10px; right: 0}

/* site original */

.dataLabel {
  color:  gray /*#d35400*/ ;
}
.dataView {
  color:  black /*#d35400*/ ;
}

.icon-label{
  float: right;
  padding-right: 1vh;
}

.card-header {
  border-top: 5px solid #00B5EC;
  background: rgba(255, 255, 255, 1);
}



/*-------------------------------------------------------*/


div.block {
  color : red;
}

img.img_access {
  width: 15px; height: 15px; margin-bottom: 0.8vh;
}


.icon-label input {
  width: 20px;
}

button {
    cursor: pointer;
    width: 30vh;
    margin-top: 2vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-size: 1rem;
    font-family: ITCAvantGarde, serif;
}

img.logo {
    object-fit: cover;
    height: 40vh;
}

input {
    border: 1px solid var(--couleur-principale);
    width: 45vh;
}












  .buttonsX {
    color: black;
    margin-top: 2vh;
    background-color: rgb(0, 133, 197);
    border-radius: 7px;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-size: 1rem;
    cursor: pointer;
    width: 30vh;
    }

/*
  container: {
    height: "90vh";
    display: "flex";
    alignItems: "center";
    textAlign: "center";
    marginBottom: '10vh';
  }
  button: {
    color: "white";
    marginTop: "2vh";
    backgroundColor: "#0085c5";
    borderRadius: "7px";
    paddingTop: "1vh";
    paddingBottom: "1vh";
    fontSize: "1rem";
    cursor: "pointer";
    width: "30vh";
    "@media only screen and (maxWidth: 1024px)": {
      width: "80vw"
    };
    "@media only screen and (minWidth: 1025px)": {
      maxHeight: "80vh";
      width: "100%"
    }
  };
  kezako: {
    color: "#0085c5";
    backgroundColor: "#FFF";
    marginTop: "2vh";
    border: "2px solid #0085c5";
    borderRadius: "7px";
    fontSize: "1rem";
    paddingTop: "1vh";
    paddingBottom: "1vh";
    cursor: "pointer";
    "@media only screen and (maxWidth: 1024px)": {
      width: "80vw"
    };
    "@media only screen and (minWidth: 1025px)": {
      maxHeight: "80vh";
      width: "100%"
    }
  };
  lien: {
    fontSize: "0.8rem";
    textDecoration: "none";
    color: "grey"
  };
  responsive: {
    height: "40vh"
  }
*/

</style>

/*
Vue : {{ $route.query }}
*/

<template>
  <div class="container justify-content-center">
    <div>
    Validation... query: {{$route.query}}
    </div>
  </div>
</template>

<script>
import MyFlashDS from "../services/MyFlashDS";

export default {
  data(){
      return {
      }
  },
  name: "UserValidationView",
  components: {
  },
  methods: {
  },
  mounted() {
    MyFlashDS.validateUser(this.$route.query).then(res=>{
      this.$emit('onAlert', 'danger', res.data);
      console.log(res.data);
      this.$router.push('/'); // {name:'main'}
    });
  }
}
</script>


<style scoped>
</style>

<template>
  <div class="container justify-content-center" style="padding-left: 0">

    <dialog ref="dialog">
      <form id="myform" method="dialog">
        id : <input ref="d_id" name="id"/><br/>
        nom: <input ref="d_name" name="name"/><br/>
          <button @click="dialogOK">OK</button>
      </form>
    </dialog>

<div class="container text-center">
  <div class="row">
    <div class="col-2">

    <!--Les profils   style 'background: '+field.color -->
    <div @click="doShow(idx)" class='field font-weight-bold mt-2' style="" v-for="(field,idx) in profils" :key="idx">
      {{field.name}}
    </div>
    <button type="button" @click="addLevel1" class="btn btn-primary buttons">{{$t('ajouter')}}</button><br/>
    <button type="button" @click="doSave" class="btn btn-outline-primary buttons mt-2">{{$t('save')}}</button>

    </div>
    <div class="col">

    <div v-for="(block,idx) in profils" :key="idx">
      <div v-if="idx==index">
      <h2>{{block.name}}</h2>
      <div v-for="(field,idf) in block.fields" :key="idf" class="text-start">
        <img v-if="field.logo" :src="field.logo" class="logo"/>
        <b>{{field.name}}</b> :
            <span v-if="field.type=='text'">libre (<a href="#" @click="field.type='list'; field.values=[]">en liste</a>)</span>
            <span v-if="field.type=='date'">Date</span>
            <span v-if="field.type=='list'">
                <div v-for="(v,iv) in field.values" :key="iv">{{v}}
                </div>
                <button type="button" @click="addLevel3(idf)" class="btn btn-primary buttons mt-2">{{$t('ajouter')}}</button>
            </span>
      </div>
      <button type="button" @click="addLevel2(idf)" class="btn btn-primary buttons mt-2">{{$t('ajouter')}}</button>

      </div>
    </div>

    </div>
  </div>
</div>

  </div>
</template>

<script>
import MyFlashDS from "../services/MyFlashDS";
//import ImageUpdater from '@/components/ImageUpdater.vue'

export default {
  data(){
      return {
        index:'',
        idf:null,

        profils: [
        /*
          {name:'Présentation', color:'#FFFFFF', fields:[
            {id:'presentation', name:'', type:'text'}
          ]},
          {name:'Réseaux Sociaux', color:'#8E44AD', fields:[
            {id:'facebook', name:'Facebook', type:'text', logo:'logo_facebook.png'},
            {id:'instagram', name:'Instagram', type:'text', logo:'logo_instagram.png'},
            {id:'twitter', name:'Twitter', type:'text', logo:'logo_twitter.png'},
            {id:'linkedin', name:'Linkedin', type:'text', logo:'logo_linkedin.png'},
            {id:'youtube', name:'Youtube', type:'text', logo:'logo_youtube.png'},
            {id:'skype', name:'Skype', type:'text', logo:'logo_skype.png'},
            {id:'discord', name:'Discord', type:'text', logo:'logo_discord.png'},
            {id:'shapr', name:'Shapr', type:'text', logo:'logo_shapr.png'},
            {id:'twitch', name:'Twitch', type:'text', logo:'logo_twitch.png'},
            {id:'snapchat', name:'Snapchat', type:'text', logo:'logo_snapchat.png'},
            {id:'tiktok', name:'TikTok', type:'text', logo:'logo_tiktok.png'},
          ]},
          {name: "Centres d'intéret", color:'#7B8D8E', fields:[
            {id:'films', name:'Films', type:'text'},
            {id:'books', name:'Livres', type:'text'},
            {id:'series', name:'Séries', type:'text'},
            {id:'music', name:'Musiques', type:'text'},
            {id:'sport', name:'Sports', type:'list', values:['course à pied','natation', 'cyclisme']},
            {id:'travel', name:'Voyages', type:'text'},
            {id:'games', name:'Jeux vidéo', type:'text'},
            {id:'artists', name:'Artistes', type:'text'},
            {id:'authors', name:'Auteurs', type:'text'},
          ]},
        */
        ],

      }
  },
  name: "AdminProfils",
  components: {
    //ImageUpdater,
  },
  methods: {
    addLevel1() {
        let name = window.prompt("Nom de la rubrique: ");
        this.profils.push({name: name, color:'white', fields:[]})
    },
    addLevel2(idf) {
        //let name = window.prompt("Nom de la rubrique: ");
        let dialog=this.$refs.dialog
        this.idf=idf;
        dialog.showModal();
        //this.profils.push({name: name, color:'white', fields:[]})
    },
    dialogOK() {
        this.profils[this.index].fields.push({id:this.$refs.d_id.value, name: this.$refs.d_name.value, type:'text'})
    },
    addLevel3(idf) {
        console.log('addLevel3', this.index, idf)
        let name = window.prompt("Nom valeur: ");
        let profils = this.profils[this.index]
        profils.fields[idf].values.push(name)
        //this.$set(this.profils[this.index].fields[idf], 'values', this.profils[this.index].fields[idf].values)
        //this.$set(this.profils[this.index].fields[idf], 'values', this.profils[this.index].fields[idf].values)
        this.$set(this.profils, this.index, profils)
        this.profils[this.index] = profils
        console.log(profils[this.index])
    },
    doShow(idx) {
        this.index=idx;
    },
    doLoadProfil() {
        MyFlashDS.getConfig('profils').then(res => {
          console.log('profils:', res.data.profils);
          if (res.data.profils) this.profils=res.data.profils;
        })
    },
    doSave() {
      MyFlashDS.saveConfig({profils: this.profils}).then(() => {
        this.doLoadProfil(this.profils);
        alert('Profils sauvés')
      });
    },
  },
  mounted() {
    this.doLoadProfil();
  },
}
</script>

<style scoped>

div.field {
  //font-weight: bold;
}

img.logo {width: 5vh;}

button {
  /*width: 20vh;*/
}

h4 {
  margin-top: 3vh;
}

img {
    max-width: 100px;
    max-height: 100px;
}

</style>

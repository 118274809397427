<template>
  <div class="container justify-content-center" style="padding-left: 0">
  <div v-if="config">

    <h4>Qu'est-ce que c'est ?</h4>
    <vue-editor v-model="config.whatisit"/>

    <h4>CGU</h4>
    <vue-editor v-model="config.cgu"/>

    <h4>Confidentialité</h4>
    <vue-editor v-model="config.cgv"/>

    <h4>Mentions légales</h4>
    <vue-editor v-model="config.mentions"/>

    <h4>Email inscription</h4>
    <p v-pre>Utiliser "{{pseudo}}", "{{email}}", "{{url}}" comme templates</p>
    <vue-editor v-model="config.email_inscription"/>

    <h4>Email oublié</h4>
    <p v-pre>Utiliser "{{pseudo}}", "{{email}}", "{{url}}" comme templates</p>
    <vue-editor v-model="config.email_forgotten"/>


    <h4>Email ajout Privé</h4>
    <p v-pre>Utiliser "{{pseudo}}", "{{url}}" comme templates</p>
    <vue-editor v-model="config.email_addPrivate"/>

    <h4></h4>
        <div class="row justify-content-center md-form md-outline">
            <button type="button" @click="doSave" class="btn btn-outline-primary buttons">{{$t('save')}}</button>
        </div>



  </div>
  </div>
</template>

<script>
import MyFlashDS from "../services/MyFlashDS";
//import ImageUpdater from '@/components/ImageUpdater.vue'
import { VueEditor } from "vue2-editor";

export default {
  data(){
      return {
        user: null,
        config: null,
      }
  },
  name: "AdminConfig",
  //props: ['user'],
  components: {
    VueEditor,
    //ImageUpdater,
  },
  methods: {
    doLoadConfig() {
        MyFlashDS.getConfig('').then(res=>{
                this.config=res.data;
                this.$emit('onAlert', 'info', 'Informations de configuration chargées')
                });
    },
    doSave() {
        MyFlashDS.saveConfig(this.config).then(res=>{
            this.$emit('onAlert', 'success', res.data)
        })
    },
  },
  mounted() {
    console.log('MOUNT AdminConfig');
    this.doLoadConfig();
  },
}
</script>

<style scoped>

button {
  width: 20vh;
}

h4 {
  margin-top: 3vh;
}

img {
    max-width: 100px;
    max-height: 100px;
}

</style>

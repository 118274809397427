import axios from 'axios';

class MyFlashDS {

  // Roles = {ADMIN: 1, SECURITY:2, PLUS: 4, SUBSCRIPTION: 8, VALIDATED: 16};

  registerUser(data) {
    console.log('>MyFlashDS.registerUser', data);
    return axios.put(process.env.VUE_APP_ROOT_API+'/users', data);
  }

  validateUser(data) {
    console.log('>MyFlashDS.validateUser', data);
    return axios.put(process.env.VUE_APP_ROOT_API+'/users/validate', data);
  }
  forgotUser(data) {
    console.log('>MyFlashDS.forgotUser', data);
    return axios.put(process.env.VUE_APP_ROOT_API+'/users/forgot', data);
  }

  loginUser(data) {
    console.log('>MyFlashDS.loginUser', data)
    return axios.post(process.env.VUE_APP_ROOT_API+'/users', data);
  }

  saveUser(data) {
    console.log('>MyFlashDS.saveUser', data)
    return axios.patch(process.env.VUE_APP_ROOT_API+'/users', data);
  }

  deleteUser() {
    console.log('>MyFlashDS.deleteUser')
    return axios.delete(process.env.VUE_APP_ROOT_API+'/users');
  }

  logoutUser() {
    console.log('>MyFlashDS.logoutUser')
    return axios.get(process.env.VUE_APP_ROOT_API+'/users/logout');
  }

  identifyUser() {
    console.log('>MyFlashDS.identifyUser');
    return axios.get(process.env.VUE_APP_ROOT_API+'/users');
  }

  //------------------------------------------------------------------

  dataGetUser(id, fields) {
    console.log('>MyFlashDS.dataGetUser', id);
    return axios.get(process.env.VUE_APP_ROOT_API+'/users/userData', {params:{id:id, fields: fields}});
  }
  dataSetUser(data) {
    console.log('>MyFlashDS.dataSetUser');
    return axios.put(process.env.VUE_APP_ROOT_API+'/users/userData', data);
  }
  getAllUsers() {
    console.log('>MyFlashDS.getAllUsers');
    return axios.get(process.env.VUE_APP_ROOT_API+'/users/findAll');
  }
  dataGetFlash(no) {
    console.log('>MyFlashDS.dataGetFlash');
    return axios.get(process.env.VUE_APP_ROOT_API+'/tags/getData?no='+no);
  }

  //------------------------------------------------------------------

  getUsers() {
    console.log('>MyFlashDS.getUsers');
    return axios.get(process.env.VUE_APP_ROOT_API+'/allUsers');
  }

/*
  test() {
    console.log('>MyFlashDS.test');
    //axios.get(process.env.VUE_APP_ROOT_API+'/users/test'); // OK
    return axios.get(process.env.VUE_APP_ROOT_API+'/users/test'); // OK
  }
*/

  //------------------------------------------------------------------

  getTags(param) {
    console.log('>MyFlashDS.getTags :', param);
    return axios.get(process.env.VUE_APP_ROOT_API+'/tags'+(param?param:''));
  }
  findTag(params){//no, id, cnt
    console.log('>MyFlashDS.findTag');
    return axios.get(process.env.VUE_APP_ROOT_API+'/tags/findOne', {params});
  }
  updateTags(data) { // {nos: '10-12', customer:'ID'}
    console.log('>MyFlashDS.updateTags');
    return axios.post(process.env.VUE_APP_ROOT_API+'/tags', data);
  }
  saveTag(data) { // {tagid:'***'}
    console.log('>MyFlashDS.ownerTag');
    return axios.put(process.env.VUE_APP_ROOT_API+'/tags', data);
  }

  //------------------------------------------------------------------

  getCustomers(type) {
    console.log('>MyFlashDS.getCustomers');
    return axios.get(process.env.VUE_APP_ROOT_API+'/customers/findAll?type='+type);
  }
  getOneCustomer(id,proj) {
    console.log('>MyFlashDS.getOneCustomer', id, proj);
    return axios.get(process.env.VUE_APP_ROOT_API+'/customers?id='+id+'&proj='+proj);
  }
  createCustomers(data) {
    console.log('>MyFlashDS.addCustomers');
    return axios.post(process.env.VUE_APP_ROOT_API+'/customers', data);
  }
  saveCustomer(data) { //
    console.log('>MyFlashDS.saveCustomer');
    return axios.put(process.env.VUE_APP_ROOT_API+'/customers', data);
  }

  //------------------------------------------------------------------

  getConfig(fields) {
    console.log('>MyFlashDS.getConfig, ', fields);
    return axios.get(process.env.VUE_APP_ROOT_API+'/config?fields='+fields);
  }
  saveConfig(data) {
    console.log('>MyFlashDS.saveConfig');
    return axios.put(process.env.VUE_APP_ROOT_API+'/config', data);
  }
/*
  saveUser(data) {
    return axios.post(process.env.VUE_APP_ROOT_API+'/users', data);
  }

*/

}

export default new MyFlashDS();

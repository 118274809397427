<template>

<div class="accordion gx-0" id="pubPartenaire">
  <div class="accordion-item gx-0">
   <div class="row justify-content-center md-form md-outline">
    <div id="collapseOne" class="accordion-collapse collapse  row justify-content-center" data-bs-parent="#pubPartenaire">
      <div class="accordion-body" :style="style" @click="openLien">
      </div>
    </div>
   </div>
    <h2 class="accordion-header">
      <button ref="b1" @click="charChg" class="accordion-button p-1" style="display: block; " type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        <div>
             <div class="container">
               <div class="row">
                <div class="col-1 mt-4"><img class="float-right" :style="'transform: rotate('+rotation+'deg)'" src="/arrow-bandeau.png"/></div>
                <div class="col-5 mt-4 text-end align-middle">{{$i18n.t('actuality')}}</div>
                <div class="col-5"><img class="logo" :src="this.img.image"/></div>
                <div class="col-1 text-start"></div>
               </div>
             </div>
        </div>
      </button>
    </h2>
  </div>
</div>

</template>

<script>
import MyFlashDS from "../services/MyFlashDS";

export default {
  data(){
      return {
        img: {bandeau: '/default_bandeau.jpg', image: '/Mascotte_01B.png'},
        customer: null,
        style: 'background-image: url("http://localhost:8090/images/bandeau-655a3eee5efbb70363149a5d.webp?ts=1700675623481");',
        rotation: 0,
      }
  },
  props:['tag'], // {owner: XXX, customer: XXX}
  name: "publicitePartenaire",
  components: {
  },
  methods: {
    timed() {
      this.$refs.b1.click() // Ouvre la pub après 2s
    },
    charChg(){
        this.rotation = 180 - this.rotation;
    },
    openLien(){
      window.open(this.customer.url, '_blank');
    },
    doSetData(data) {
        this.img.bandeau = (data.bandeau ? process.env.VUE_APP_ROOT_API+'/..'+data.bandeau : '/default_bandeau.jpg')
        this.img.image = (data.image ? process.env.VUE_APP_ROOT_API+'/..'+data.image : '/Mascotte_01B.png')
        this.style='background-image: url("'+this.img.bandeau+'");';
    },
    doLoadImages(){
        console.log('Pub,data:', this.tag)
            MyFlashDS.getOneCustomer(this.tag.customer, 'name bandeau image url').then(res=>{
              this.customer=res.data;
              this.doSetData(res.data);
            });
    },
  },
  mounted() {
    this.doLoadImages()
    window.setTimeout(this.timed, 2000);
  }
}
</script>

<style scoped>
#pubPartenaire {
  position : absolute;
  width: 100vw;
  margin-left: -50vw;
  left: 50%;
  opacity: 0.9;
  z-index: 2;
}

img.logo {
  height: 8vh;
}
div.accordion-body {
  padding: 0px;

  height: 40vh;
  background-size: 100% 100%;
  background-size: cover;
  background-position: center;
  padding-right: 0;
  padding-left: 0;
}
button.accordion-button {
  background: white;
}

</style>

import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    'fr': {
      test: "fr-test",
      list_tuto: "Liste des tutoriels",
      add: "Ajouter",
      search: "Rechercher",
      remove_all: "Tout supprimer",
      click_tuto: "Cliquer sur un tuto...",

      login: "ME CONNECTER",
      logout: "Déconnexion",
      connect: "Se connecter",
      pseudo: "Pseudo",
      validate: "Valider",
      save: "Sauver",
      welcome: "Bonjour, ",
      subscribe: "Souscrire",
      gomyflash: "Bienvenue sur My-Flash",
      validation: "En validant ce formulaire, vous acceptez les ",
      whatisit: "QU'EST-CE QUE C'EST ?",
      cgu: "conditions générales d'utilisation",
      cgv: "Politique de confidentialité",
      mentions: "Mentions Légales",
      inscription: "M'INSCRIRE",
      return: "Retour",
      email: "Adresse email",
      password: "Mot de passe",
      confirmpassword: "Confirmer Mot de Passe",
      passwordforgot: "Mot de passe oublié ?",
      administration: 'Administration',
      connectflash: "CONNECTER CE FLASH",
      seeprofile: "Voir le profil",
      addcontact: "Ajouter le contact",
      addcircle: "My Circle",
      addsmart: "Smartphone",
      accept: "Accepter",
      secourir: "Secourir",
      public: "Public",
      private: "Privé",
      security: "Secours",
      modify: "modifier",
      contacts: "Contacts",
      flashs: "Mes Flashs",
      shop: "Boutique",
      createdAt: "Crée le",
      reinit: "Réinitialisation mot de passe",
      freeflash: "Libérer le flash",
      freeflasbutton: "Oublier",
      confirmFlashForget: "Voulez-vous vraiment oublier ce Flash ?",
      deleteUser: "Supprimer mon compte",
      confirmUserForget: "Voulez-vous vraiment supprimer votre compte ?",
      actuality: "Actualités",

      public_vue: 'Visible par tout le monde',
      private_vue: 'Visible par les personnes que vous définissez',
      security_vue: 'Visible par les services de sécurité',

      // Tags
      id: "Identifiant",
      no: "Numéro",
      customer: "Partenaire",
      customers: "Partenaires",
      tags: "Tags",
      users: "Utilisateurs",
      isAdmin: "Admin ?",
      isSecurity: "Sécurité ?",
      isSubscribed: "Abonnement ?",
      isCircle: "Circle+ ?",
      isValidated: "Validé ?",
      owner: "Propriétaire",

      // Fields
      b_presentation: 'Présentation',
        presentation: '',
      b_identity: 'Identité',
        name: 'Nom',
        surname: 'Prénom',
        birthdate: 'Date naissance',
      b_coord: 'Coordonnées',
        address: 'Adresse',
        cp: 'CP',
        town: 'Ville',
      b_network: 'Réseaux sociaux',
        facebook: 'Facebook',
        instagram: 'Instagram',
        twitter: 'Twitter',
      b_interest: "Centres d'intéret",
        films: 'Films',
        books: 'Livres',
        series: 'Séries',
      b_sante: 'Santé',
        allergy: 'Allergies',
        treatment: 'Traitements',
        operation: 'Prénom',
      b_professionel: 'Professionnel',
        job: 'Métier',
        company: 'Société',
        lastexp: 'Dernière expérience professionnelle',

    },
    'en': {
      test: "en-test",
      list_tuto: "Tutorials list",
      add: "Add",
      search: "Search",
      remove_all: "Remove all",
      click_tuto: "Please click on a Tutorial...",
    },

};

const i18n = new VueI18n({
  locale: 'fr', // set locale
  fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
});

export default i18n;

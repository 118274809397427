<template>
  <div class="container text-center">

    <NavBar :user="user" />

    <div v-if="!user">

        <div v-if="!isConnecting && !isRegistering">
            <div v-if="true" class="row justify-content-center">
               <div id='logo1' class='col d-flex align-items-center justify-content-center'><img class="logo" :src="isRegistering||isConnecting ? 'logo_circle.png' : 'logo_circle_text.png'" alt="My Flash logo"></div>
            </div>
            <div v-if="!isConnecting && !isRegistering" class="row justify-content-center">
               <div class='col'><button @click="isConnecting=true" type="button" class="btn btn-primary buttons">{{$t('login')}}</button></div>
            </div>
            <div class="row justify-content-center">
               <div class='col'><button @click="isRegistering=true" type="button" class="btn btn-outline-primary buttons">{{$t('inscription')}}</button></div>
            </div>
            <WhatIsIt/>
        </div>

        <UserRegister v-if="isRegistering" @doReturn="doReturn" @onAlert="onAlert"/>
        <UserLogin v-if="isConnecting" @doReturn="doReturn" @onAlert="onAlert"/>
    </div>

    <div v-else>
      <UserView @onAlert="onAlert"/>
    </div>

    <BottomBar/>

  </div>
</template>

<script>
import MyFlashDS from "../services/MyFlashDS";
import UserRegister from '@/components/UserRegister.vue'
import UserLogin from '@/components/UserLogin.vue'
import WhatIsIt from '@/components/WhatIsIt.vue'
import UserView from '@/views/UserView.vue'
import NavBar from '@/components/NavBar.vue'
import BottomBar from '@/components/BottomBar.vue'


export default {
  data(){
      return {
        user: null,
        isConnecting: false,
        isRegistering: false,
        isAdministration: false,
      }
  },
  name: "MainView",
  components: {
    UserRegister,
    UserLogin, UserView,
    WhatIsIt, BottomBar,
    NavBar,
  },
  watch:{
    $route (to, from){
      console.log('ROUTE change ', from, to)
      this.isConnecting = to.query.isConnecting;
      this.isRegistering = to.query.isRegistering;
    }
  },
  methods: {
    onAlert(variant, message) {this.$emit('onAlert', variant, message)},
    doReturn(){
      console.log('doReturn...')
      this.isConnecting=false;
      this.isRegistering=false;
      MyFlashDS.identifyUser().then(res=>{
        //alert(res.data);
        this.user=res.data;
        console.log(res.status, '<MyFlashDS.identifyUser', res.data);
        });
    },
    doAdmin(){
    },
    myChange() {
    },
  },
  mounted() {
    console.log('MOUNT MainMenu', this.$route.path, this.$route.query);
    if (this.$route.query.forgotten) {
        this.isRegistering=true;
    }
    //this.onAlert("secondary", "start MainMenu");
    if (this.$route.path=='/logout'){
        MyFlashDS.logoutUser()
        this.user=null;
        this.$router.push('/')
    } else {
        MyFlashDS.identifyUser().then(res=>{
            this.user=res.data;
            console.log(res.status, '<MyFlashDS.identifyUser', res.data);
            });
    }


  }
}
</script>


<style scoped>
button {
    cursor: pointer;
    width: 30vh;
    margin-top: 2vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-size: 1rem;
    font-family: ITCAvantGarde, serif;
}

img.logo {
    //object-fit: cover;
    //height: 40vh;
    max-width: 30vh;
}

input {
    border: 1px solid var(--couleur-principale);
    width: 45vh;
}

#logo1 {
  height: 50vh;
}










  .buttonsX {
    color: black;
    margin-top: 2vh;
    background-color: rgb(0, 133, 197);
    border-radius: 7px;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-size: 1rem;
    cursor: pointer;
    width: 30vh;
    }

/*
  container: {
    height: "90vh";
    display: "flex";
    alignItems: "center";
    textAlign: "center";
    marginBottom: '10vh';
  }
  button: {
    color: "white";
    marginTop: "2vh";
    backgroundColor: "#0085c5";
    borderRadius: "7px";
    paddingTop: "1vh";
    paddingBottom: "1vh";
    fontSize: "1rem";
    cursor: "pointer";
    width: "30vh";
    "@media only screen and (maxWidth: 1024px)": {
      width: "80vw"
    };
    "@media only screen and (minWidth: 1025px)": {
      maxHeight: "80vh";
      width: "100%"
    }
  };
  kezako: {
    color: "#0085c5";
    backgroundColor: "#FFF";
    marginTop: "2vh";
    border: "2px solid #0085c5";
    borderRadius: "7px";
    fontSize: "1rem";
    paddingTop: "1vh";
    paddingBottom: "1vh";
    cursor: "pointer";
    "@media only screen and (maxWidth: 1024px)": {
      width: "80vw"
    };
    "@media only screen and (minWidth: 1025px)": {
      maxHeight: "80vh";
      width: "100%"
    }
  };
  lien: {
    fontSize: "0.8rem";
    textDecoration: "none";
    color: "grey"
  };
  responsive: {
    height: "40vh"
  }
*/

</style>
